import { combineReducers } from 'redux';
import logOutReducer from './logout';
import authReducer from './auth';
import homeCarousel from './homeCarousel';
import getRSSFeedReducer from './getRSSFeed';
import clearHistory from './clearHistory';
import researchReducer from './research';
import updateAlertReducer from "./updateAlert";
import reisReducer from './reis';
import notifications from './notifications';
import getAllNewReports from './getAllNewReports';
import getAllFeaturedReports from './getAllFeaturedReports';
import getAllPopularReports from './getAllPopularReports';
import addToFav from './addToFav';
import addToBasket from './addToBasket';
import addToAlert from './addToAlert';
import getMyAllFilesReducer from './myFiles';
import getMyAllFavoritesReducer from './myFavorites';
import saveSettings from './saveSettings';
import createAlert  from './createAlert';
import generateSearchUrl from './generateSearchUrl'
import getAllReisDeliverablesReducer from './reisDeliverables'
import getAllHelpCenter from './getAllHelpCenter';
import contactUs from './contactUs';
import getMyAllAlertsReducer from './myAlerts';
import getAllFaqsReducer  from './seeMoreFaq';
import getAllMyBasketReducer  from './allMyBasket';
import removeFromBasketReducer  from './removeFromBasket';
import basketEmailBatchReducer from './basketEmailBatch';
import alertSearchResultReducer from './alertSearchResult';
import updateLocalRegionReducer from './region';
import basketDownload from './basketDownload';
import removeFromSearchAlert from "./removeFromSearchAlert";
import searchHistoryReducer from "./searchHistory";
import viewDocDownloadByIdReducer from "./docDownloadByDocid";
import getESDocDetailsReducer from './getESDocDetails';
import getESUserRolesReducer from './getESUserRoles';

export default combineReducers({
    auth: authReducer,
    homeCarousel: homeCarousel,
    getRSSFeed: getRSSFeedReducer,
    clearHistory: clearHistory,
    research: researchReducer,
    updateAlert:updateAlertReducer,
    reis: reisReducer,
    notifications:notifications,
    logOut:logOutReducer,
    getAllNewReports:getAllNewReports,
    getAllFeaturedReports:getAllFeaturedReports,
    getAllPopularReports:getAllPopularReports,
    addToFav:addToFav,
    addToBasket:addToBasket,
    addToAlert:addToAlert,
    myFiles:getMyAllFilesReducer,
    myFavorites: getMyAllFavoritesReducer,
    saveSettings:saveSettings,
    createAlert:createAlert,
    generateSearchUrl:generateSearchUrl,
    getAllReisDeliverables:getAllReisDeliverablesReducer,
    getAllHelpCenter:getAllHelpCenter,
    contactUs:contactUs,
    myAlerts: getMyAllAlertsReducer,
    getAllFaqs:getAllFaqsReducer,
    getAllBasket:getAllMyBasketReducer,
    removeFromBasket:removeFromBasketReducer,
    basketEmailBatch:basketEmailBatchReducer,
    alertSearchResult:alertSearchResultReducer,
    updateLocalRegion: updateLocalRegionReducer,
    basketDownload:basketDownload,
    removeFromSearchAlert:removeFromSearchAlert,
    searchHistory:searchHistoryReducer,
    alertEmailDownload: viewDocDownloadByIdReducer,
    getESDocDetails: getESDocDetailsReducer,
    getESUserRoles: getESUserRolesReducer,
});
