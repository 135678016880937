import {
    GENERATE_SEARCH_URL_STARTED,
    GENERATE_SEARCH_URL_SUCCESS,
    GENERATE_SEARCH_URL_FAILURE,
} from '../constants/auth.constant';
  
  const initialState = {
    loading: false,
    generateSearchUrlInfo:{
      url:""
    },
    error: null
  };
  
  export default function researchReducer(state = initialState, action) {
    switch (action.type) {
      case GENERATE_SEARCH_URL_STARTED:
        return {
          ...state,
          loading: true
        };
      case GENERATE_SEARCH_URL_SUCCESS:
        
        // console.log("generateSearchUrlInfo ",action.payload);
        return {
          ...state,
          loading: false,
          error: null,
          generateSearchUrlInfo: {...state.generateSearchUrlInfo, url:action.payload}
        };
      case GENERATE_SEARCH_URL_FAILURE:
        return {
          ...state,
          loading: true,
          error: action.payload.error
        };
      default:
        return state;
    }
  }