import {
    MY_ALERTS_STARTED,
    MY_ALERTS_SUCCESS,
    MY_ALERTS_FAILURE,
    REMOVE_FROM_ALERTS_STARTED,
    REMOVE_FROM_ALERTS_SUCCESS,
    REMOVE_FROM_ALERTS_FAILURE,
    } from "../constants/myAlerts.constant";
    
    const initialState = {
      loading: false,
      myAllAlerts: [],
      my_report_alerts: [],
      my_search_alert: [],
      error: null,
      removed_from_alerts:{
        loading: false,
        error:null,
        removed_docId: ""
      }
    };
    
    export default function getMyAllAlertsReducer(state = initialState, action) {
      switch (action.type) {
        case MY_ALERTS_STARTED:
          return {
            ...state,
            loading: true
          };
        case MY_ALERTS_SUCCESS:
          return {
            ...state,
            loading: false,
            error: null,
            my_report_alerts: [action.payload.my_report_alerts],
            my_search_alert: [action.payload.my_search_alert]
          };
        case MY_ALERTS_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.payload.error
          };
          case REMOVE_FROM_ALERTS_STARTED:
            return {
              ...state,
              removed_from_alerts: {...state.removed_from_alerts, loading: true}
            };
          case REMOVE_FROM_ALERTS_SUCCESS:
            return {
              ...state,
              removed_from_alerts: {...state.removed_from_alerts, loading: false, removed_docId: action.payload, error: null}
            };
          case REMOVE_FROM_ALERTS_FAILURE:
            return {
              ...state,
              removed_from_alerts: {...state.removed_from_alerts, loading: false, error: action.payload.error}
            };
        default:
          return state;
      }
    }