import {
    CONTACTUS_STARTED,
    CONTACTUS_SUCCESS,
    CONTACTUS_FAILURE
} from "../constants/helpcenter.constant";

const initialState = {
    loading  : false,
    contactUsInfo:[],
    error:null
}

const contactUs = ( state = initialState, action ) => {
    switch(action.type) {
        case CONTACTUS_STARTED :
            return {
                ...state,
                loading:true
            }
        case CONTACTUS_SUCCESS :
            return {
                    ...state,
                    loading:false,
                    error:null,
                    contactUsInfo : [...state.contactUsInfo,...action.payload]
            }
        case CONTACTUS_FAILURE :
            return {
                    ...state,
                    loading:false,
                    error: action.payload.error
            }
        default :
            return state;
    }
}

export default contactUs;