import {
  NOTIFICATIONS_STARTED,
  NOTIFICATIONS_SUCCESS,
  NOTIFICATIONS_FAILURE
} from '../constants/auth.constant';

const initialState = {
  loading: false,
  notificationInfo:[],
  error: null,
  countHide:false,
};

export default function noticationReducer(state = initialState, action) {
  switch (action.type) {
    case NOTIFICATIONS_STARTED:
      return {
        ...state,
        loading: true
      };
    case NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        countHide:true,
        notificationInfo: action.payload
      };
    case NOTIFICATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    default:
      return state;
  }
}

