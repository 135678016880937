import {
  SAVE_SETTINGS_STARTED,
  SAVE_SETTINGS_SUCCESS,
  SAVE_SETTINGS_FAILURE,
} from "../constants/auth.constant";

const initialState = {
  loading: false,
  saveSettingsInfo: [],
  error: null,
};

export default function saveSettingsReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_SETTINGS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case SAVE_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        saveSettingsInfo: [...state.saveSettingsInfo, ...action.payload],
      };
    case SAVE_SETTINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
