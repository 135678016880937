import {
    SWITCH_REGION_SUCCESS
} from "../constants/region.constant";

const initialState = {
    regionChange: {
        regionId: ""
    }
};

export default function updateLocalRegionReducer(state = initialState, action) {
    switch (action.type) {
        case SWITCH_REGION_SUCCESS:
            return {
                ...state,
                regionChange: { 
                    ...state.regionChange, 
                    regionId: action.payload
                }
            };
        default:
            return state;
    }
}