import {
    GET_ALL_FEATURED_REPORTS_STARTED,
    GET_ALL_FEATURED_REPORTS_SUCCESS,
    GET_ALL_FEATURED_REPORTS_FAILURE
  } from '../constants/auth.constant';
  
  const initialState = {
    loading: false,
    getAllFeaturedReportsInfo: {},
    error: null
  };
  
  export default function getAllFeaturedReportsReducer(state = initialState, action) {
    switch (action.type) {
      case GET_ALL_FEATURED_REPORTS_STARTED:
        return {
          ...state,
          loading: true
        };
      case GET_ALL_FEATURED_REPORTS_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          getAllFeaturedReportsInfo: {...state.getAllFeaturedReportsInfo, ...action.payload.featured_reports}
        };
      case GET_ALL_FEATURED_REPORTS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error
        };
      default:
        return state;
    }
  }