import {
    ADD_TO_ALERT_STARTED,
    ADD_TO_ALERT_SUCCESS,
    ADD_TO_ALERT_FAILURE,
  } from "../constants/alerts.constant";
  
  const initialState = {
    loading: false,
    addToAlertsInfo: {},
    error: null
  };
  
  export default function addToAlertsReducer(state = initialState, action) {
    switch (action.type) {
      case ADD_TO_ALERT_STARTED:
        return {
          ...state,
          loading: true
        };
      case ADD_TO_ALERT_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          addToAlertsInfo: action.payload
        };
      case ADD_TO_ALERT_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error
        };
      default:
        return state;
    }
  }