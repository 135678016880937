import {
    OKTA_AUTH_STARTED,
    OKTA_AUTH_SUCCESS,
    OKTA_AUTH_FAILURE
  } from '../constants/auth.constant';

import { ajaxInstance } from '../axiosRetryAgent';
import { API_URL } from "../constants";
import * as isVersion from 'react-device-detect';

export const loginAuth = ({ idToken, clientId, version }) => {
    const email = JSON.parse(localStorage.getItem("okta-token-storage")).idToken.claims.email;
    let currentDate = new Date();
    if (isVersion.isMobile) {
      version = 1
      console.log("This is mobile "+ version)
    }
    else if(isVersion.isDesktop) {
      version = 0
      console.log("This is desktop "+ version)
    }   
    return dispatch => {
        dispatch(loginAuthStarted());
        const instance = ajaxInstance();
        instance
            .get(`${API_URL}home/GetAllDefaultSettings?version=` + version, {
                params: {
                    currentDate: `${currentDate.getMonth()+1}/${currentDate.getDate()}/${currentDate.getFullYear()}`,
                    email: email,
                }
            })
            .then(res => {
                localStorage.setItem('metadata', JSON.stringify(res.data));
                //localStorage.setItem('xsrfToken', JSON.stringify(res.headers['xsrf-token']));
                dispatch(loginAuthSuccess(res.data));
            })
            .catch(err => {
                dispatch(loginAuthFailure(err.message));
            });
           
    };
};

const loginAuthSuccess = loggedUserInfo => ({
    type: OKTA_AUTH_SUCCESS,
    payload: {
        ...loggedUserInfo
    }
});

const loginAuthStarted = () => ({
    type: OKTA_AUTH_STARTED
});

const loginAuthFailure = error => ({
    type: OKTA_AUTH_FAILURE,
    payload: {
        error
    }
});