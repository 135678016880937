import {
  LOGOUT_STARTED,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
} from "../constants/auth.constant";

const initialState = {
  loading: false,
  logOut: [],
  error: null,
};

export default function logOutReducer(state = initialState, action) {
  switch (action.type) {
    case LOGOUT_STARTED:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        logOut: [...state.logOut, action.payload],
      };
    case LOGOUT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
