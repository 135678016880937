export const OKTA_LOGIN = "OKTA_LOGIN";
export const OKTA_LOGOUT = "OKTA_LOGOUT";
export const OKTA_AUTH_STARTED = "OKTA_AUTH_STARTED";
export const OKTA_AUTH_SUCCESS = "OKTA_AUTH_SUCCESS";
export const OKTA_AUTH_FAILURE = "OKTA_AUTH_FAILURE";
export const LOGOUT_STARTED = "LOGOUT_STARTED";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const HOME_CAROUSEL_STARTED = "HOME_CAROUSEL_STARTED";
export const HOME_CAROUSEL_SUCCESS = "HOME_CAROUSEL_SUCCESS";
export const HOME_CAROUSEL_FAILURE = "HOME_CAROUSEL_FAILURE";
export const CLEAR_HISTORY_STARTED = "CLEAR_HISTORY_STARTED";
export const CLEAR_HISTORY_SUCCESS = "CLEAR_HISTORY_SUCCESS";
export const CLEAR_HISTORY_FAILURE = "CLEAR_HISTORY_FAILURE";
export const NOTIFICATIONS_STARTED = "NOTIFICATIONS_STARTED";
export const NOTIFICATIONS_SUCCESS = "NOTIFICATIONS_SUCCESS";
export const NOTIFICATIONS_FAILURE = "NOTIFICATIONS_FAILURE";
export const GET_ALL_NEW_REPORTS_STARTED = "GET_ALL_NEW_REPORTS_STARTED";
export const GET_ALL_NEW_REPORTS_SUCCESS = "GET_ALL_NEW_REPORTS_SUCCESS";
export const GET_ALL_NEW_REPORTS_FAILURE = "GET_ALL_NEW_REPORTS_FAILURE";
export const GET_ALL_FEATURED_REPORTS_STARTED = "GET_ALL_FEATURED_REPORTS_STARTED";
export const GET_ALL_FEATURED_REPORTS_SUCCESS = "GET_ALL_FEATURED_REPORTS_SUCCESS";
export const GET_ALL_FEATURED_REPORTS_FAILURE = "GET_ALL_FEATURED_REPORTS_FAILURE";
export const GET_ALL_POPULAR_REPORTS_STARTED = "GET_ALL_POPULAR_REPORTS_STARTED";
export const GET_ALL_POPULAR_REPORTS_SUCCESS = "GET_ALL_POPULAR_REPORTS_SUCCESS";
export const GET_ALL_POPULAR_REPORTS_FAILURE = "GET_ALL_POPULAR_REPORTS_FAILURE";
export const GET_RSS_FEED_STARTED = "GET_RSS_FEED_STARTED";
export const GET_RSS_FEED_SUCCESS = "GET_RSS_FEED_SUCCESS";
export const GET_RSS_FEED_FAILURE = "GET_RSS_FEED_FAILURE";
export const ADD_TO_FAVORITES_STARTED = "ADD_TO_FAVORITES_STARTED";
export const ADD_TO_FAVORITES_SUCCESS = "ADD_TO_FAVORITES_SUCCESS";
export const ADD_TO_FAVORITES_FAILURE = "ADD_TO_FAVORITES_FAILURE";
export const ADD_TO_BASKET_STARTED = "ADD_TO_BASKET_STARTED";
export const ADD_TO_BASKET_SUCCESS = "ADD_TO_BASKET_SUCCESS";
export const ADD_TO_BASKET_FAILURE = "ADD_TO_BASKET_FAILURE";
export const SAVE_SETTINGS_STARTED = "SAVE_SETTINGS_STARTED";
export const SAVE_SETTINGS_SUCCESS = "SAVE_SETTINGS_SUCCESS";
export const SAVE_SETTINGS_FAILURE = "SAVE_SETTINGS_FAILURE";
export const CREATE_ALERT_FOR_SEARCH_STARTED = "CREATE_ALERT_FOR_SEARCH_STARTED";
export const CREATE_ALERT_FOR_SEARCH_SUCCESS = "CREATE_ALERT_FOR_SEARCH_SUCCESS";
export const CREATE_ALERT_FOR_SEARCH_FAILURE = "CREATE_ALERT_FOR_SEARCH_FAILURE";
export const GENERATE_SEARCH_URL_STARTED = "GENERATE_SEARCH_URL_STARTED";
export const GENERATE_SEARCH_URL_SUCCESS = "GENERATE_SEARCH_URL_SUCCESS";
export const GENERATE_SEARCH_URL_FAILURE = "GENERATE_SEARCH_URL_FAILURE";
export const GET_ALL_REIS_DELIVERABLES_STARTED = "GET_ALL_REIS_DELIVERABLES_STARTED";
export const GET_ALL_REIS_DELIVERABLES_SUCCESS = "GET_ALL_REIS_DELIVERABLES_SUCCESS";
export const GET_ALL_REIS_DELIVERABLES_FAILURE = "GET_ALL_REIS_DELIVERABLES_FAILURE";
export const GET_ALL_FAQS_STARTED = "GET_ALL_FAQS_STARTED";
export const GET_ALL_FAQS_SUCCESS = "GET_ALL_FAQS_SUCCESS";
export const GET_ALL_FAQS_FAILURE = "GET_ALL_FAQS_FAILURE";
export const GET_ALL_MY_BASKET_STARTED = "GET_ALL_MY_BASKET_STARTED";
export const GET_ALL_MY_BASKET_SUCCESS = "GET_ALL_MY_BASKET_SUCCESS";
export const GET_ALL_MY_BASKET_FAILURE = "GET_ALL_MY_BASKET_FAILURE";
export const REMOVE_FROM_BASKET_STARTED = "REMOVE_FROM_BASKET_STARTED";
export const REMOVE_FROM_BASKET_SUCCESS = "REMOVE_FROM_BASKET_SUCCESS";
export const REMOVE_FROM_BASKET_FAILURE = "REMOVE_FROM_BASKET_FAILURE";
export const BASKET_EMAIL_BATCH_DOWNLOAD_STARTED = "BASKET_EMAIL_BATCH_DOWNLOAD_STARTED";
export const BASKET_EMAIL_BATCH_DOWNLOAD_SUCCESS = "BASKET_EMAIL_BATCH_DOWNLOAD_SUCCESS";
export const BASKET_EMAIL_BATCH_DOWNLOAD_FAILURE = "BASKET_EMAIL_BATCH_DOWNLOAD_FAILURE";
export const BASKET_DOWNLOAD_STARTED = "BASKET_DOWNLOAD_STARTED";
export const BASKET_DOWNLOAD_SUCCESS = "BASKET_DOWNLOAD_SUCCESS";
export const BASKET_EMAILSENT_SUCCESS ="BASKET_EMAILSENT_SUCCESS "
export const BASKET_DOWNLOAD_FAILURE = "BASKET_DOWNLOAD_FAILURE";
export const ALERT_SEARCH_RESULT_STARTED = "ALERT_SEARCH_RESULT_STARTED";
export const ALERT_SEARCH_RESULT_SUCCESS = "ALERT_SEARCH_RESULT_SUCCESS";
export const ALERT_SEARCH_RESULT_FAILURE = "ALERT_SEARCH_RESULT_FAILURE";
export const SEARCH_HISTORY_STARTED = "SEARCH_HISTORY_STARTED";
export const SEARCH_HISTORY_SUCCESS = "SEARCH_HISTORY_SUCCESS";
export const SEARCH_HISTORY_FAILURE = "SEARCH_HISTORY_FAILURE";
export const VIEW_DOCUMENT_DOWNLOAD_BYID_STARTED = "VIEW_DOCUMENT_DOWNLOAD_BYID_STARTED";
export const VIEW_DOCUMENT_DOWNLOAD_BYID_SUCCESS = "VIEW_DOCUMENT_DOWNLOAD_BYID_SUCCESS";
export const VIEW_DOCUMENT_DOWNLOAD_BYID_FAILURE = "VIEW_DOCUMENT_DOWNLOAD_BYID_FAILURE";
export const VIEW_DOCUMENTS_DOWNLOAD_BYID_STARTED = "VIEW_DOCUMENTS_DOWNLOAD_BYID_STARTED";
export const VIEW_DOCUMENTS_DOWNLOAD_BYID_SUCCESS = "VIEW_DOCUMENTS_DOWNLOAD_BYID_SUCCESS";
export const VIEW_DOCUMENTS_DOWNLOAD_BYID_FAILURE = "VIEW_DOCUMENTS_DOWNLOAD_BYID_FAILURE";
export const GET_ES_DOC_DETAILS_STARTED = "GET_ES_DOC_DETAILS_STARTED";
export const GET_ES_DOC_DETAILS_SUCCESS = "GET_ES_DOC_DETAILS_SUCCESS";
export const GET_ES_DOC_DETAILS_FAILURE = "GET_ES_DOC_DETAILS_FAILURE";
export const GET_ES_USER_ROLES_STARTED = "GET_ES_USER_ROLES_STARTED";
export const GET_ES_USER_ROLES_SUCCESS = "GET_ES_USER_ROLES_SUCCESS";
export const GET_ES_USER_ROLES_FAILURE = "GET_ES_USER_ROLES_FAILURE";