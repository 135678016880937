import {
    REMOVE_FROM_BASKET_STARTED,
    REMOVE_FROM_BASKET_SUCCESS,
    REMOVE_FROM_BASKET_FAILURE,
  } from "../constants/auth.constant";
  
  const initialState = {
    loading: false,
    removeFromBasketInfo: [],
    error: null,
  };
  
  export default function removeFromBasketReducer(state = initialState, action) {
    switch (action.type) {
      case REMOVE_FROM_BASKET_STARTED:
        return {
          ...state,
          loading: true,
        };
      case REMOVE_FROM_BASKET_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          removeFromBasketInfo: action.payload,
        };
      case REMOVE_FROM_BASKET_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error,
        };
      default:
        return state;
    }
  }
  