import {
    SEARCH_HISTORY_STARTED,
    SEARCH_HISTORY_SUCCESS,
    SEARCH_HISTORY_FAILURE,
    CLEAR_HISTORY_SUCCESS
  } from '../constants/auth.constant';
  
  const initialState = {
    loading: false,
    searchHistoryInfo: [],
    error: null
  };
  
  export default function searchHistoryReducer(state = initialState, action) {
    switch (action.type) {
      case SEARCH_HISTORY_STARTED:
        return {
          ...state,
          loading: true
        };
      case SEARCH_HISTORY_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          searchHistoryInfo: action.payload
        };
      case SEARCH_HISTORY_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error
        };
      case CLEAR_HISTORY_SUCCESS:
        state.searchHistoryInfo = [];
        return state;
      default:
        return state;
    }
  }