import React, { useEffect, useState } from "react";
import { Modal, Form } from "react-bootstrap";
import "./Settings.css";
import back from "../../../../assets/images/back.svg";
import downarrow from "../../../../assets/images/dropdown.svg";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { env } from "../../../../constants";
import { loginAuth } from "../../../../actions/auth";
import DropdownComponent from "../../../Common/Dropdown";
import { saveSettings } from "../../../../actions/saveSettings";
import { clearCache } from "clear-cache";
import { withRouter } from "react-router";
import { useTranslation } from "react-i18next";

const Settings = (props) => {
  const { userId, defaultlanguageid, defaultregionrd,code } = props.loggedUserInfo;
  const [open, setOpen] = useState(true);
  const [regId, setRegId] = useState({
    visible: false,
    data: [],
  });
  const [lanId, setLanId] = useState({
    visible: false,
    data: [],
  });
  const [regionSettings, setRegionSettings] = useState({
    value: "",
    visible: false,
  });
  const [languageSettings, setLanguageSettings] = useState({
    value: "",
    visible: false,
  });

  const [defReg, setDefReg] = useState({
    data: [],
  });
  const [defLan, setDefLan] = useState({
    data: [],
  });
  useEffect(() => {
    const regionArr = [];
    const defLangArr = [];
    if (props.loggedUserInfo.region_master) {
      let defaultReg =
        props.loggedUserInfo.region_master &&
        props.loggedUserInfo.region_master.filter(
          (a) => a.regionid === props.loggedUserInfo.defaultregionrd
        );
      defaultReg.map((data) =>
        regionArr.push({
          label: data.regionname,
          id: data.regionid,
        })
      );
    }

    if (props.loggedUserInfo.language_master) {
      let defaultLan = props.loggedUserInfo.language_master.filter(
        (a) => a.languageid === props.loggedUserInfo.defaultlanguageid
      );
      defaultLan.map((data) =>
        defLangArr.push({
          label: data.languagename,
          id: data.languageid,
        })
      );
    }

    setDefLan({
      ...defLan,
      data: defLangArr,
    });
    setDefReg({
      ...defReg,
      data: regionArr,
    });
  }, [
    props.loggedUserInfo.region_master &&
      props.loggedUserInfo.region_master.length > 0,
    props.loggedUserInfo.language_master &&
      props.loggedUserInfo.language_master > 0,
  ]);

  const defaultRegion = defReg.data.map((a) => a.label);
  const defaultLanguage = defLan.data.map((a) => a.label);
  const onClickRegHandler = (e) => {
    const value = e.target.innerHTML;
    const loopRegionArr = [];
    let loopReg = props.loggedUserInfo.region_master.filter(
      (a) => a.regionname === value
    );
    loopReg.map((data) =>
      loopRegionArr.push({
        label: data.regionname,
        id: data.regionid,
      })
    );
    setRegId({
      ...regId,
      data: loopRegionArr,
    });

    setRegionSettings({ value });
  };
  const selectedRegionId = regId.data.map((a) => a.id);
  const onClickLangHandler = (e) => {
    const value = e.target.innerHTML;
    const loopLanArr = [];
    let loopLan = props.loggedUserInfo.language_master.filter(
      (a) => a.languagename === value.trim()
    );
    loopLan.map((data) =>
      loopLanArr.push({
        label: data.languagename,
        id: data.languageid,
        code:data.code
      })
    );
    
    setLanId({
      ...lanId,
      data: loopLanArr,
    });
    setLanguageSettings({ value });
  };
  const selectedLanId = lanId.data.map((a) => a.id);
  const selectedLanCode = lanId.data.map((a) => a.code);
 
  const handleClose = () => {
    setRegionSettings({ ...regionSettings, value: "" });
    setLanguageSettings({  ...languageSettings, value: ""  });
    props.close(false);
    
  };

  const handleSave = () => {
    let regionId = selectedRegionId.toString()
      ? selectedRegionId.toString()
      : defaultregionrd;
    let langNum = selectedLanId.toString()
      ? selectedLanId.toString()
      : defaultlanguageid;

    props.saveSettings({
      userId,
      regionId,
      langNum,
    });
    
    i18n.changeLanguage(
      selectedLanCode[0]?selectedLanCode[0]:code
    );
    if (props.history) {
      props.history.push(`${env}home`);
    }

    // window.location.replace(`${env}home`);

    // clearCache()
    // window.location.reload();
    // window.location = (`${env}home`)
    //  props.close(false)
    // this.forceUpdate();
  };
  const onOpenOrCloseDropdown = (dropdownData, setDropdownData, isConfirm) => {
    if (dropdownData.visible) {
      // Logic for close
      if (isConfirm) {
        dropdownData.backup = JSON.parse(JSON.stringify(dropdownData));
        dropdownData.backup.backup = {};
        setDropdownData({
          ...dropdownData,
          visible: false,
        });
      } else {
        setDropdownData({
          ...dropdownData.backup,
          visible: false,
        });
      }
    } else {
      // logic for open

      dropdownData.backup = JSON.parse(JSON.stringify(dropdownData));
      dropdownData.backup.backup = {};
      setDropdownData({
        ...dropdownData,
        visible: true,
      });
    }
  };
  const { t, i18n } = useTranslation();
 
  const regionTypesDropdown = (
    <Form id="formgroup">
      <Form.Group>
        {props.loggedUserInfo && props.loggedUserInfo.region_master
          ? props.loggedUserInfo.region_master.map((data) => (
              <div className="optionStyle">
                <div className={data.regionname === regionSettings.value ? "optionBold":"" } onClick={onClickRegHandler} >{data.regionname}</div>
                <hr aria-hidden="true"/>
              </div>
            ))
          : ""}
      </Form.Group>
    </Form>
  );
  const languageTypesDropdown = (
    <div className="dropdownWrapper" id="dropdownWrapper">
      <Form>
        <Form.Group>
          {props.loggedUserInfo && props.loggedUserInfo.region_master
            ? props.loggedUserInfo.language_master.map((data) => (
                <div className="optionStyle">
                  <div className={data.languagename === languageSettings.value ? "optionBold":"" } onClick={onClickLangHandler} >{data.languagename}</div>
                  <hr aria-hidden="true"/>
                </div>
              ))
            : ""}
        </Form.Group>
      </Form>
    </div>
  );
  return (
    <>
      <div className="desktopSet">
        <Modal
          id="regionDropdownModal"
          show={props.show}
          onHide={handleClose}
          keyboard={false}
          autoFocus={true}
          className="modalStyle"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body className="padleft">
            <Modal.Title>
              <Link to={`${env}/home`}>
                <img src={back} className="backButton mr-5" />
              </Link>
              <span className="titleStyle text-center">{t("settings")}</span>
            </Modal.Title>

            <div className="defaultPage">{t("defaulthomepage")}</div>
            <div className="regionDrop">
              <div className="  regionDropdownSize">
                <DropdownComponent
                  className="regionDropdown"
                  contentClassName="regionDropdownContent"
                  title={
                    regionSettings.value == ""
                      ? defaultRegion
                      : regionSettings.value
                  }
                  show={regionSettings.visible}
                  content={regionTypesDropdown}
                  onToggle={() =>
                    onOpenOrCloseDropdown(
                      regionSettings,
                      setRegionSettings,
                      false
                    )
                  }
                />
              </div>
            </div>
            <div>
              <div className="defaultscreen">{t("screenlanguage")}</div>
              <div className="langDrop">
                <div className="  langDropdownSize" >
                  <DropdownComponent
                    className="langDropdown"
                    
                    id="languageDropdown"
                    contentClassName="langDropdownContent"
                    title={
                      languageSettings.value == ""
                        ? defaultLanguage
                        : languageSettings.value
                    }
                    show={languageSettings.visible}
                    content={languageTypesDropdown}
                    
                    onToggle={() =>
                      onOpenOrCloseDropdown(
                        languageSettings,
                        setLanguageSettings,
                        false
                      )
                      
                    }
                   
                  />
                </div>
              </div>

              {/* <div>
                <button className="confirmStyle" data-testid="confirm-settings">
                  <span className="confirmText">{t("confirm")}</span>
                </button>
              </div> */}
              <div className="footerButtons">
                <button
                  className="cancelStyle "
                  onClick={handleClose}
                  data-testid="confirm-settings"
                >
                  <span className="textStyle">{t("cancel")}</span>
                </button>
                <button
                  className="saveStyle"
                  onClick={handleSave}
                  data-testid="cancel-settings"
                >
                  <span className="saveTextStyle">{t("save")} </span>
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = ({ auth }) => {
  const loggedUserInfo = auth.loggedUserInfo;
  return {
    loggedUserInfo,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loginAuth: (authInfo) => {
      dispatch(loginAuth(authInfo));
    },
    saveSettings: (params) => {
      dispatch(saveSettings(params));
    },
  };
};
const SettingsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings);

export default SettingsContainer;
