import {
    VIEW_DOCUMENT_DOWNLOAD_BYID_STARTED,
    VIEW_DOCUMENT_DOWNLOAD_BYID_SUCCESS,
    VIEW_DOCUMENT_DOWNLOAD_BYID_FAILURE,
    VIEW_DOCUMENTS_DOWNLOAD_BYID_STARTED,
    VIEW_DOCUMENTS_DOWNLOAD_BYID_SUCCESS,
    VIEW_DOCUMENTS_DOWNLOAD_BYID_FAILURE,
} from '../constants/auth.constant';
import { API_URL} from "../constants";
import { ajaxInstance } from '../axiosRetryAgent';
const instance = ajaxInstance();

export const viewDocDownloadById = (data) => {
 const { userId,docId,doc_type,title } = data;
 return (dispatch) => {
   dispatch(fileDownloadStarted());
   instance
     .get(`${API_URL}download/ViewDocumentDownloadByDocid`, {
      headers: {
        'Content-Type': 'application/octet-stream',
      },
       params: {
         userId: userId,
         DocId: docId,
       },
       responseType: 'blob'
     })
     .then((res) => {
      console.log("filename2: ")
      const fileName = res.headers['content-disposition'].split('filename=')[1].split(';')[0].replace('"', '').replace('"', '')
      console.log("filename1: "+ fileName)
       var file = new File([res.data], {
         lastModified: Date.now(),
       });
       const url = window.URL.createObjectURL(new Blob([res.data]));
       const link = document.createElement("a");
       link.href = url;
       link.setAttribute("download", `${fileName}`);
       document.body.appendChild(link);
       link.click();
       dispatch(fileDownloadSuccess(file));
     })
     .catch((err) => {
       dispatch(fileDownloadFailure(err.message));
     });
 };
}

export const viewDocDownloadByMultipleId = (data) => {
  const { userId, BatchNum, filesDocIds } = data;
  return (dispatch) => {
    dispatch(filesDownloadStarted());
 
    instance
      .get(`${API_URL}download/DocumentDownloadByBatchid`, {
        headers: {
          'Content-Type': 'application/octet-stream',
        },
        params:{
          userId: userId,
          BatchNum: BatchNum
        },
        responseType: 'blob',
        timeout:20000000000,
      })
 
      .then((res) => {
        const fileName = res.headers['content-disposition'].split('filename=')[1].split(';')[0]
        console.log("filename: "+fileName)        
        var file = new Blob([res.data], {
          lastModified: Date.now(),
        });
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileName}`);
        document.body.appendChild(link);
        link.click();
        dispatch(filesDownloadSuccess(file));
      })
      .catch((err) => {
        dispatch(filesDownloadFailure(err.message));
      });
  };
}

const fileDownloadSuccess = (fileResponse) => ({
 type: VIEW_DOCUMENT_DOWNLOAD_BYID_SUCCESS,
 payload: {
   ...fileResponse,
 },
});

const fileDownloadStarted = () => ({
 type: VIEW_DOCUMENT_DOWNLOAD_BYID_STARTED,
});

const fileDownloadFailure = (error) => ({
 type: VIEW_DOCUMENT_DOWNLOAD_BYID_FAILURE,
 payload: {
   error,
 },
});  

const filesDownloadSuccess = (fileResponse) => ({
  type: VIEW_DOCUMENTS_DOWNLOAD_BYID_SUCCESS,
  payload: {
    ...fileResponse,
  },
 });
 
 const filesDownloadStarted = () => ({
  type: VIEW_DOCUMENTS_DOWNLOAD_BYID_STARTED,
 });
 
 const filesDownloadFailure = (error) => ({
  type: VIEW_DOCUMENTS_DOWNLOAD_BYID_FAILURE,
  payload: {
    error,
  },
 });  