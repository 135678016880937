import {
    ALERT_SEARCH_RESULT_STARTED,
    ALERT_SEARCH_RESULT_SUCCESS,
    ALERT_SEARCH_RESULT_FAILURE
  } from '../constants/auth.constant';
  
  const initialState = {
    loading: false,
    alertSearchResultInfo: [],
    error: null
  };
  
  export default function alertSearchResultReducer(state = initialState, action) {
    switch (action.type) {
      case ALERT_SEARCH_RESULT_STARTED:
        return {
          ...state,
          loading: true
        };
      case ALERT_SEARCH_RESULT_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          alertSearchResultInfo: [ action.payload.search_result]
        };
      case ALERT_SEARCH_RESULT_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error
        };
      default:
        return state;
    }
  }