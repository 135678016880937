import {
    SEARCH_RESULT_STARTED,
    SEARCH_RESULT_SUCCESS,
    SEARCH_RESULT_FAILURE
} from '../constants/research.constant';
  
  const initialState = {
    loading: false,
    searchstatus: "",
    search_results: [],
    error: null
  };
  
  export default function researchReducer(state = initialState, action) {
    switch (action.type) {
      case SEARCH_RESULT_STARTED:
        return {
          ...state,
          loading: true,
        };
      case SEARCH_RESULT_SUCCESS:
        return {
          ...state,
          loading: false,
          searchstatus: action.payload.status,
          search_results: [action.payload.data]
        };
      case SEARCH_RESULT_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error,
          search_results:[]
        };
      default:
        return state;
    }
  }