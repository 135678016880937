import {
  MY_FAVORITES_STARTED,
  MY_FAVORITES_SUCCESS,
  MY_FAVORITES_FAILURE,
  REMOVE_FROM_FAVORITES_STARTED,
  REMOVE_FROM_FAVORITES_SUCCESS,
  REMOVE_FROM_FAVORITES_FAILURE,G
  } from "../constants/myFavorites.constant";
  
  const initialState = {
    loading: false,
    myAllFavorites: [],
    error: null,
    removed_from_favorites:{
      loading: false,
      error:null,
      removed_docId: ""
    }
  };
  
  export default function getMyAllFavoritesReducer(state = initialState, action) {
    switch (action.type) {
      case MY_FAVORITES_STARTED:
        return {
          ...state,
          loading: true
        };
      case MY_FAVORITES_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          myAllFavorites: [action.payload]
        };
      case MY_FAVORITES_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error
        };
        case REMOVE_FROM_FAVORITES_STARTED:
          return {
            ...state,
            removed_from_favorites: {...state.removed_from_favorites, loading: true}
          };
        case REMOVE_FROM_FAVORITES_SUCCESS:
          return {
            ...state,
            removed_from_favorites: {...state.removed_from_favorites, loading: false, removed_docId: action.payload, error: null}
          };
        case REMOVE_FROM_FAVORITES_FAILURE:
          return {
            ...state,
            removed_from_favorites: {...state.removed_from_favorites, loading: false, error: action.payload.error}
          };
      default:
        return state;
    }
  }