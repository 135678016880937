import {
    ADD_TO_FAVORITES_STARTED,
    ADD_TO_FAVORITES_SUCCESS,
    ADD_TO_FAVORITES_FAILURE
  } from '../constants/auth.constant';
  
  const initialState = {
    loading: false,
    addToFavoritesInfo: {},
    error: null
  };
  
  export default function addToFavotitesReducer(state = initialState, action) {
    switch (action.type) {
      case ADD_TO_FAVORITES_STARTED:
        return {
          ...state,
          loading: true
        };
      case ADD_TO_FAVORITES_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          addToFavoritesInfo: action.payload
        };
      case ADD_TO_FAVORITES_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error
        };
      default:
        return state;
    }
  }