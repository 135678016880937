import {
    UPDATE_ALERT_STARTED,
    UPDATE_ALERT_SUCCESS,
    UPDATE_ALERT_FAILURE,
  } from '../constants/alerts.constant';
  
  const initialState = {
    loading: false,
    updateAlertInfo: [],
    error: null
  };
  
  export default function updateAlertReducer(state = initialState, action) {
    switch (action.type) {
      case UPDATE_ALERT_STARTED:
        return {
          ...state,
          loading: true
        };
      case UPDATE_ALERT_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          updateAlertInfo: [ action.payload]
        };
      case UPDATE_ALERT_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error
        };
      default:
        return state;
    }
  }