import {
   LOGOUT_STARTED,
   LOGOUT_SUCCESS,
   LOGOUT_FAILURE
} from '../constants/auth.constant';
import { API_URL, API_CLIENT } from "../constants";
//import axios from 'axios';
import { ajaxInstance } from '../axiosRetryAgent';
import * as isVersion from 'react-device-detect';

export const logOut = () => {
    let version = 0
    if (isVersion.isMobile) {
        version = 1
        console.log("This is mobile "+ version)
      }
      else if(isVersion.isDesktop) {
          version = 0
        console.log("This is desktop "+ version)
      }   
    const idToken = JSON.parse(localStorage.getItem('okta-token-storage')).idToken.value;
    const metadata = JSON.parse(localStorage.getItem('metadata'));
    return dispatch => {
        dispatch(logOutStarted());
        const instance = ajaxInstance();
        instance
            .get(`${API_URL}home/LogOffUserSession`,
                { 
                    // headers: {
                    //     Authorization: 'Bearer ' + idToken,
                    //     clientId: API_CLIENT
                    // },
                    params: {
                        'userId': metadata.userId,
                        'sessionNum':metadata.sessionId,
                        'version':version
                    }
                })
            .then(res => {
                dispatch(logOutSuccess(res.data));
            })
            .catch(err => {
                dispatch(logOutFailure(err.message));
            });
    };
};

const logOutSuccess = logOutInfo => ({
    type:LOGOUT_SUCCESS,
    payload: {
        ...logOutInfo
    }
});

const logOutStarted = () => ({
    type:LOGOUT_STARTED
});

const logOutFailure = error => ({
    type:LOGOUT_FAILURE,
    payload: {
        error
    }
});