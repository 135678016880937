import React from 'react';
import './Footer.css';
import JllLogo from '../../../assets/images/JLLFooterLogo.png';
import facebook from '../../../assets/images/facebook.svg';
import twitter from '../../../assets/images/twitter.svg';
import linkedin from '../../../assets/images/linkedin.svg';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t, i18n } = useTranslation();
    const currentYear = (new Date().getFullYear());
    return (
        <div>
            <footer >
                <div className=" footer footerStyle desktopFooter">
                    <div className="row ">
                        <div className="col-md-6 text-center text-md-left ">
                            <div ><img className="footerLogo" src={JllLogo}  alt="footer_logo"/>
                                <span className="copyRight" data-testid="CopyRight">
                                    {t("copyright", {currentYear})}
                        </span>
                            </div>
                        </div>
                        <div className="col-md-0 text-white text-center text-md-left ">
                        </div>
                        <div className="col-md-6 text-white  text-center text-md-center">
                            <span className="ps " data-testid="Privacy_Statement">
                            <a href="https://www.us.jll.com/en/privacy-statement" target="_blank" tabindex="0" aria-label="Privacy Statement link opens in a new tab" className="bb">{t("privacy")} </a>
                            <span className="tou"> <a href="https://www.us.jll.com/en/terms-of-service " target="_blank" tabindex="0" aria-label="Terms of Use link opens in a new tab" className="bb">{t("terms")}</a></span>
                                </span>
                            <span className=" follow">
                                <span className="fu" data-testid="Follow_Us">
                                    {t("follow")}
                                    </span>
                                    <a href="https://www.facebook.com/jll" target="_blank"  tabindex="0" aria-label="Facebook app opens in a new tab" className="bb"><img src={facebook} className="facebook" alt="facebook-logo"/></a>
                                    <a href="https://twitter.com/jllresearch" target="_blank" tabindex="0" aria-label="Twitter app opens in a new tab" className="bb"><img src={twitter} className="twitter" alt="twitter-logo"/></a>
                                    <a href="https://www.linkedin.com/company/jll" target="_blank" tabindex="0" aria-label="Linked in opens in a new tab" className="bb"><img src={linkedin} className="linkedin" alt="linkedin-logo"/></a>
                                </span>
                        </div>
                    </div>
                </div>
                <div className="mobileFooter footer ">
                    <div className="line footerStyle">
                        <div className="line">
                            <img className="footerLogo" src={JllLogo} alt="footer_logo" />
                        </div>
                        <div className="line follow">
                                    <span className="fu">
                                    {t("follow")}
                                    </span>
                                    <a href="https://www.facebook.com/jll" target="_blank" tabindex="0" aria-label="Facebook app opens in a new tab" className="bb"><img src={facebook} className="facebook" alt="facebook-logo"/></a>
                                    <a href="https://twitter.com/jllresearch" target="_blank" tabindex="0" aria-label="Twitter app opens in a new tab" className="bb"><img src={twitter} className="twitter" alt="twitter-logo"/></a>
                                    <a href="https://www.linkedin.com/company/jll" target="_blank" tabindex="0" aria-label="Linked in opens in a new tab" className="bb"><img src={linkedin} className="linkedin" alt="linkedin-logo"/></a>
                                </div>
                        <div className="line right text-white">
                           
                            <div className="line service">
                                <div className="ps line">
                                 <a href="https://www.us.jll.com/en/privacy-statement" target="_blank" tabindex="0" aria-label="Privacy Statement link opens in a new tab" className="bb">{t("privacy")} </a>
                                 <span className="tou">  <a href="https://www.us.jll.com/en/terms-of-service " target="_blank" tabindex="0" aria-label="Terms of Use link opens in a new tab" className="bb">{t("terms")}</a></span>
                                </div>
                               
                            </div>
                            <div className="copyRight  line" tabindex="-1">
                                {t("copyright", {currentYear})}
                            </div>
                        </div>

                    </div>
                </div>
            </footer>

            </div>
    )
}

export default Footer;