import {
    HOME_CAROUSEL_STARTED,
    HOME_CAROUSEL_SUCCESS,
    HOME_CAROUSEL_FAILURE
  } from '../constants/auth.constant';
  
  const initialState = {
    loading: false,
    homeCarouselInfo: {},
    error: null
  };
  
  export default function todosReducer(state = initialState, action) {
    switch (action.type) {
      case HOME_CAROUSEL_STARTED:
        return {
          ...state,
          loading: true
        };
      case HOME_CAROUSEL_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          homeCarouselInfo: {...state.homeCarouselInfo, ...action.payload}
        };
      case HOME_CAROUSEL_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error
        };
      default:
        return state;
    }
  }