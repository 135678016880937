import {
    BASKET_DOWNLOAD_STARTED,
    BASKET_DOWNLOAD_SUCCESS,
    BASKET_DOWNLOAD_FAILURE
} from '../constants/auth.constant';

const initialState = {
    loading: false,
    basketDownloadInfo: [],
    error: null,
};
  
  export default function basketEmailBatchReducer(state = initialState, action) {
    switch (action.type) {
      case BASKET_DOWNLOAD_STARTED:
        return {
          ...state,
          loading: true,
        };
      case BASKET_DOWNLOAD_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          basketDownloadInfo: [...state.basketDownloadInfo, ...action.payload],
        };
      case BASKET_DOWNLOAD_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error,
        };
      default:
        return state;
    }
  }
  