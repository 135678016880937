import React from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import { SecureRoute, Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, EVENT_EXPIRED, toRelativeUrl } from '@okta/okta-auth-js';
import Routes from './routes/index';
import SurveyRoute from './routes/survey';
import { env, ISSUER, CLIENT } from "./constants";
import AlertEmail from './components/AlertEmail/AlertEmail';
import AlertsEmail from './components/AlertsEmail/AlertsEmail';
export default function App() {
const oktaAuth = new OktaAuth({
  issuer: ISSUER,
  clientId: CLIENT,
  redirectUri: window.location.origin + '/responsive/portal/implicit/callback',
  tokenManager: {
    autoRenew: false,
    autoRemove: false
  }
});
const history = useHistory();
const restoreOriginalUri = async (oktaAuth, originalUri) => {
  console.log("original uri: "+originalUri);
  if (originalUri === 'https://bcpdev.eu.joneslanglasalle.com/responsive/portal/research') {
    history.replace(toRelativeUrl('/responsive/portal/', window.location.origin)) 
  } 
  else if (originalUri === 'https://bcpdev.eu.joneslanglasalle.com/responsive/portal/SeriesReportAlertsList') {
    history.replace(toRelativeUrl('/responsive/portal/', window.location.origin)) 
  }
  else {
    history.replace(toRelativeUrl(originalUri, window.location.origin))
  }
}
oktaAuth.tokenManager.on(EVENT_EXPIRED, async (key) => {
  const existingToken = await oktaAuth.tokenManager.get(key);
  oktaAuth.token.renew(existingToken)
    .then(newToken => {
        oktaAuth.tokenManager.add(key, newToken);
    })
    .catch(e => {
      oktaAuth.tokenManager.remove(key);
    });
});
  return (
    <>
      <Security
         oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}
      >
       <Switch>
        <Route path='/responsive/portal/implicit/callback' component={LoginCallback} />        
        <SecureRoute path="/responsive/portal/survey" component={SurveyRoute} />
        <SecureRoute path="/responsive/portal/AlertEmail/DocNum=:DocNum" component={AlertEmail} />
        <SecureRoute path="/responsive/portal/AlertsEmail/BatchNum=:BatchNum" component={AlertsEmail} />
        <SecureRoute path="/responsive/portal/" component={Routes} />
        </Switch>
      </Security>
    </>
  );
}