import {
  FILE_DOWNLOAD_STARTED,
  FILE_DOWNLOAD_SUCCESS,
  FILE_DOWNLOAD_FAILURE,
  DOWNLOAD_FILES_STARTED,
  DOWNLOAD_FILES_SUCCESS,
  DOWNLOAD_FILES_FAILURE,
  DEFINITIONS_FILES_STARTED,
  DEFINITIONS_FILES_SUCCESS,
  DEFINITIONS_FILES_FAILURE,
} from "../constants/reis.constant";

const initialState = {
  file: {
    loading: false,
    results: [],
    error: null
  },
  files: {
    loading: false,
    results: [],
    error: null
  },
  defintions: {
    loading:false,
    results:[],
    error:null
  },
  error: null,
};

export default function downloadFilesReducer(state = initialState, action) {
  switch (action.type) {
    case FILE_DOWNLOAD_STARTED:
      return {
        ...state,
        file: {
          loading: true,
        },
      };
    case FILE_DOWNLOAD_SUCCESS:
      return {
        ...state,
        file: { loading: false, error: null, results: action.payload },
      };
    case FILE_DOWNLOAD_FAILURE:
      return {
        ...state,
        file: { loading: false , error: action.payload.error },
      };
    case DOWNLOAD_FILES_STARTED:
      return {
        ...state,
        files: {
          loading: true,
        },
      };
    case DOWNLOAD_FILES_SUCCESS:
      return {
        ...state,
        files: { ...state.files, loading: false, error: null },
      };
    case DOWNLOAD_FILES_FAILURE:
      return {
        ...state,
        files: { loading: false, error: action.payload.error },
        
      };
      case DEFINITIONS_FILES_STARTED:
        return {
          ...state,
          defintions: {...state.defintions, loading: true}
        };
      case DEFINITIONS_FILES_SUCCESS:
        return {
          ...state,
          defintions: {...state.defintions, loading: false, results: action.payload, error: null}
        };
      case DEFINITIONS_FILES_FAILURE:
        return {
          ...state,
          defintions: {...state.defintions, loading: false, error: action.payload.error}
        };
    default:
      return state;
  }
}
