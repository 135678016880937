import {
    CLEAR_HISTORY_STARTED,
    CLEAR_HISTORY_SUCCESS,
    CLEAR_HISTORY_FAILURE
  } from '../constants/auth.constant';
  
  const initialState = {
    loading: false,
    clearHistory: [],
    error: null
  };
  
  export default function todosReducer(state = initialState, action) {
    switch (action.type) {
      case CLEAR_HISTORY_STARTED:
        return {
          ...state,
          loading: true
        };
      case CLEAR_HISTORY_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          clearHistory: [...state.clearHistory, action.payload]
        };
      case CLEAR_HISTORY_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error
        };
      default:
        return state;
    }
  }