import {
    SAVE_SETTINGS_STARTED,
    SAVE_SETTINGS_SUCCESS,
    SAVE_SETTINGS_FAILURE
} from '../constants/auth.constant';
import { API_URL } from "../constants";
import JsCookie from "js-cookie";
//import axios from 'axios';
import { ajaxInstance } from '../axiosRetryAgent';
const instance = ajaxInstance();

export const saveSettings = (data) => {
    //const idToken = JSON.parse(localStorage.getItem('okta-token-storage')).idToken.value;
   // const metadata = JSON.parse(localStorage.getItem('metadata'));
    const {userId,  regionId, langNum } = data;
    return dispatch => {
        dispatch(saveSettingsStarted());
        instance
            .post(`${API_URL}Home/SaveUserPreference`, data,
                {
                    //headers: {
                        //'X-XSRF-TOKEN':  JSON.parse(localStorage.getItem('xsrfToken')),
                    //},
                    params: {
                        userId: userId,
                        regionId: regionId,
                        langNum: langNum,
                     }
                })
            .then(res => {
                JsCookie.set("tempRegionId", regionId);
                JsCookie.set("tempLangId", langNum);
                /* JsCookie.set("tempRegion", regionId); */
                window.location.reload()
                dispatch(saveSettingsSuccess(res.data));
                
            })
            .catch(err => {
                dispatch(saveSettingsFailure(err.message));
               
            });
    };
};

const saveSettingsSuccess = saveSettingsInfo => ({
    type: SAVE_SETTINGS_SUCCESS,
    payload: {
        ...saveSettingsInfo
    }
});

const saveSettingsStarted = () => ({
    type: SAVE_SETTINGS_STARTED
});

const saveSettingsFailure = error => ({
    type: SAVE_SETTINGS_FAILURE,
    payload: {
        error
    }
});