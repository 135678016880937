import {
    VIEW_DOCUMENT_DOWNLOAD_BYID_STARTED,
    VIEW_DOCUMENT_DOWNLOAD_BYID_SUCCESS,
    VIEW_DOCUMENT_DOWNLOAD_BYID_FAILURE,
    VIEW_DOCUMENTS_DOWNLOAD_BYID_STARTED,
    VIEW_DOCUMENTS_DOWNLOAD_BYID_SUCCESS,
    VIEW_DOCUMENTS_DOWNLOAD_BYID_FAILURE,
  } from "../constants/auth.constant";
  
  const initialState = {
    file: {
      loading: false,
      viewDocDownloadByIdInfo: [],
      error: null,
    },
    files: {
      loading: false,
      viewDocDownloadByMultipleIdInfo: [],
      error: null,
    },
    error: null,
  };
  
  export default function viewDocDownloadByIdReducer(state = initialState, action) {
    switch (action.type) {
      case VIEW_DOCUMENT_DOWNLOAD_BYID_STARTED:
        return {
          ...state,
          file: {
            loading: true,
          },
        };
      case VIEW_DOCUMENT_DOWNLOAD_BYID_SUCCESS:
        return {
          ...state,
          file: { ...state.file, loading: false, error: null },
          viewDocDownloadByIdInfo: [...state.file.viewDocDownloadByIdInfo, ...action.payload],
        };
      case VIEW_DOCUMENT_DOWNLOAD_BYID_FAILURE:
        return {
          ...state,
          file: { loading: false , error: action.payload.error },
        };
      case VIEW_DOCUMENTS_DOWNLOAD_BYID_STARTED:
        return {
          ...state,
          files: {
            loading: true,
          },
        };
      case VIEW_DOCUMENTS_DOWNLOAD_BYID_SUCCESS:
        return {
          ...state,
          files: { ...state.files, loading: false, error: null },
          viewDocDownloadByMultipleIdInfo: [...state.files.viewDocDownloadByMultipleIdInfo, ...action.payload],
        };
      case VIEW_DOCUMENTS_DOWNLOAD_BYID_FAILURE:
        return {
          ...state,
          files: { loading: false, error: action.payload.error },
        };
      default:
        return state;
    }
  }
  