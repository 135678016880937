import {
    BASKET_EMAIL_BATCH_DOWNLOAD_STARTED,
    BASKET_EMAIL_BATCH_DOWNLOAD_SUCCESS,
    BASKET_EMAIL_BATCH_DOWNLOAD_FAILURE,
  } from "../constants/auth.constant";
  
  const initialState = {
    loading: false,
    basketEmailBatchInfo: [],
    error: null,
  };
  
  export default function basketEmailBatchReducer(state = initialState, action) {
    switch (action.type) {
      case BASKET_EMAIL_BATCH_DOWNLOAD_STARTED:
        return {
          ...state,
          loading: true,
        };
      case BASKET_EMAIL_BATCH_DOWNLOAD_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          basketEmailBatchInfo: [...state.basketEmailBatchInfo, ...action.payload],
        };
      case BASKET_EMAIL_BATCH_DOWNLOAD_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error,
        };
      default:
        return state;
    }
  }
  