import React from 'react';
import { Dropdown } from 'react-bootstrap';
import downArrow from "../../assets/images/dropdown.svg"

const DropdownComponent = (props) => {
    const { className, contentClassName, title, show, content, onToggle,onClick,onKeyPress, id, key, onSelect } = props;
    return (
        <Dropdown alignRight show={show} onToggle={onToggle} onClick={onClick} onKeyPress={onKeyPress} focusFirstItemOnShow='keyboard' key={key} onSelect={onSelect}>
            <Dropdown.Toggle className={className} id={id}>
                {title}<img className={"downArrow float-right" + (!show ? "" : " rotateimg180 float-right")} src={downArrow} />
            </Dropdown.Toggle>
            <Dropdown.Menu className={contentClassName}>
                {content}
            </Dropdown.Menu>
        </Dropdown>
    )
}
export default DropdownComponent;