import {
    GET_RSS_FEED_STARTED,
    GET_RSS_FEED_SUCCESS,
    GET_RSS_FEED_FAILURE
  } from '../constants/auth.constant';
  
  const initialState = {
    loading: false,
    getRSSFeedInfo: [],
    error: null,
    length: 0
  };
  
  export default function getRSSFeedReducer(state = initialState, action) {
    switch (action.type) {
      case GET_RSS_FEED_STARTED:
        return {
          ...state,
          loading: true
        };
      case GET_RSS_FEED_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          getRSSFeedInfo: [action.payload],
          length: action.length
        };
      case GET_RSS_FEED_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error
        };
      default:
        return state;
    }
  }