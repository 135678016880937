import {
    GET_ES_DOC_DETAILS_STARTED,
    GET_ES_DOC_DETAILS_SUCCESS,
    GET_ES_DOC_DETAILS_FAILURE
  } from '../constants/auth.constant';
  
  const initialState = {
    loading: false,
    getESDocDetailsInfo: [],
    error: null
  };
  
  export default function getESDocDetailsReducer(state = initialState, action) {
    switch (action.type) {
      case GET_ES_DOC_DETAILS_STARTED:
        return {
          ...state,
          loading: true
        };
      case GET_ES_DOC_DETAILS_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          getESDocDetailsInfo: action.payload
        };
      case GET_ES_DOC_DETAILS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error
        };
      default:
        return state;
    }
  }