import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import XHR from "i18next-xhr-backend";
import languageCS from "./language/cs-CZ/translate.json";
import languageDE from "./language/de/translate.json";
import languageENUS from "./language/en/translate.json";
import languageES from "./language/es/translate.json";
import languageENGB from "./language/uk/translate.json";
import languageENMX from "./language/es-mx/translate.json";
import languageENFR from "./language/fr/translate.json";
import languageFRCA from "./language/FR-CA/translate.json";
import languageHR from "./language/hr/translate.json";
import languageIT from "./language/IT/translate.json";
import languageHU from "./language/hu/translate.json";
import languageNL from "./language/nl-be/translate.json";
import languagePL from "./language/pl/translate.json";
import languagePTBR from "./language/PT-BR/translate.json"; 
import languageptPT from "./language/pt-PT/translate.json";
import languageRO from "./language/RO/translate.json";
import languageSK from "./language/SK/translate.json";
import languageSR from "./language/SR/translate.json";
import languageTR from "./language/TR/translate.json";
import languageRU from "./language/ru/translate.json";
import languageFI from "./language/fi/translate.json";
import languageSVSE from "./language/sv/translate.json";
import languageJAJP from "./language/ja/translate.json";
import languageZHCHT from "./language/zh-Hant/translate.json";
import languageZHHAns from "./language/zh-Hans/translate.json"

const options = {
  resources: {
    "cs-CZ":languageCS,
    "DE":languageDE,
    "en-GB":languageENGB,
    "en-US":languageENUS,
    "ES": languageES,
    "ES-MX":languageENMX,
    "FR":languageENFR,
    "FR-CA":languageFRCA,
    "HR":languageHR,
    "IT":languageIT,
    "HU":languageHU,
    "NL":languageNL,
    "PL":languagePL,
    "pt-BR":languagePTBR,
    "pt-PT":languageptPT,
    "RO":languageRO,
    "SK":languageSK,
    "SR":languageSR,
    "FI":languageFI,
    "sv-SE":languageSVSE,
    "TR":languageTR,
    "RU":languageRU,
    "zh-Hans":languageZHHAns,
    "ja":languageJAJP,
    "zh-Hant":languageZHCHT
  },
  fallbackLng: "en",
  ns: ["translations"],
  defaultNS: "translations",
  order: ["navigator"],
  debug: true,
  caches: [],
  excludeCacheFor: ["cimode"],
  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ",",
    format: (value, format, lng) => {
      if (format === "uppercase") return value.toUpperCase();
      return value;
    },
  },
  react: {
    useSuspense: false,
    wait: true,
    bindI18n: "languageChanged loaded",
    bindStore: "added removed",
    nsMode: "default",
  },
  detection: {
    order: ["navigator", "cookie"],
  },
  wait: process && !process.release,
};

if (process && !process.release) {
  i18n.use(XHR).use(initReactI18next).use(LanguageDetector);
}

if (!i18n.isInitialized) {
  i18n.init(options);
}

export default i18n;
