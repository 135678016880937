import {
    CREATE_ALERT_FOR_SEARCH_STARTED,
    CREATE_ALERT_FOR_SEARCH_SUCCESS,
    CREATE_ALERT_FOR_SEARCH_FAILURE,
} from '../constants/auth.constant';
  
  const initialState = {
    loading: false,
    createAlertInfo: [],
    error: null
  };
  
  export default function researchReducer(state = initialState, action) {
    switch (action.type) {
      case CREATE_ALERT_FOR_SEARCH_STARTED:
        return {
          ...state,
          loading: true
        };
      case CREATE_ALERT_FOR_SEARCH_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          createAlertInfo: [ ...state.createAlertInfo,action.payload]
        };
      case CREATE_ALERT_FOR_SEARCH_FAILURE:
        return {
          ...state,
          loading: true,
          error: action.payload.error
        };
      default:
        return state;
    }
  }