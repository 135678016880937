import React, { useState, useEffect, useRef } from "react";
import "../MenuTabs/Header/Header.css";
import "../MenuTabs/Home/Home.css";
import "../MenuTabs/Footer/Footer.css";
import "./AlertEmail.css"
import {useParams} from 'react-router-dom';
import { connect } from "react-redux";
import { loginAuth } from "../../actions/auth";
import { logOut } from "../../actions/logout";
import { viewDocDownloadById } from "../../actions/docDownloadByDocid";
import SettingsContainer from "../MenuTabs/Header/Settings/Settings";
import region from "../../assets/region.svg";
import JllLogo from '../../assets/images/JLLFooterLogo.png';
import facebook from '../../assets/images/facebook.svg';
import twitter from '../../assets/images/twitter.svg';
import linkedin from '../../assets/images/linkedin.svg';
import { useOktaAuth } from "@okta/okta-react";
import { API_CLIENT, env } from "../../constants";
import { useTranslation } from "react-i18next";
import JLLLogo from "../../assets/images/JLLHeaderLogo.png";
import ResearchLogo from "../../assets/images/ReSearchLogo.png";
import settings from "../../assets/images/settings.svg";
import logoutIcon from "../../assets/images/logout.svg";
import headerImage from "../../assets/images/help-center-header.jpg";
import JsCookie from "js-cookie";
import { SWITCH_REGION_SUCCESS } from "../../constants/region.constant";
import { Popover, Overlay } from "react-bootstrap";
import { Helmet } from "react-helmet"
import Spinner from "../Common/Spinner";
import surveyroute from '../../routes/survey';


function Download(props) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  var userId = props.loggedUserInfo.userId;
  var defaultregionrd = "9963b25b-05b1-4177-a9e6-b89034400965";
  const [defreg, setDefreg] = useState({
    visible: false,
    data: [],
  });

  const [loop, setLoop] = useState({
    visible: false,
    data: [],
  });

  const [regionTypes, setRegionTypes] = useState({
    visible: false,
    data: [],
  });
  const [clicked, setClicked] = useState(false);

  const [dataBack, setDataBAck] = useState([]);
 
  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (props.loggedUserInfo.code) {
      i18n.changeLanguage(props.loggedUserInfo.code);
    }
  }, [props.loggedUserInfo.code]);

  const handledata = (e) => {
    let country = e.target.text;
    const loopRegionArr = [];
    let loopReg =
      props.loggedUserInfo.region_master &&
      props.loggedUserInfo.region_master.filter((a) => a.regionname == country);
    loopReg.map((data) =>
      loopRegionArr.push({
        label: data.regionname,
        id: data.regionid,
      })
    );
    setLoop({
      ...loop,
      data: loopRegionArr,
    });
    setDataBAck([...country]);
    setClicked(true);
    setRegionTypes({
      ...regionTypes,
      data: regionTypes.data
        .filter((a) => a.label !== e.target.text)
        .concat(defreg.data.splice(0, 1)),
    });
    setDefreg({
      ...defreg,
      data: defreg.data.concat(loopRegionArr),
    });
    
    var defaultregionId = "9963b25b-05b1-4177-a9e6-b89034400965";
    props.saveSettings({
      userId,
      defaultregionId,
    });
    setS(false);
  };

  useEffect(() => {
    const regionArr = [];
    const defRegionArr = [];
    var regionId = "9963b25b-05b1-4177-a9e6-b89034400965";

    if (props.loggedUserInfo.region_master) {
      let reg =
        props.loggedUserInfo.region_master &&
        props.loggedUserInfo.region_master.filter(
          (a) => a.regionid !== regionId
        );

      reg.map((data) =>
        regionArr.push({
          label: data.regionname,
          id: data.regionid,
        })
      );
      let defaultReg =
        props.loggedUserInfo.region_master &&
        props.loggedUserInfo.region_master.filter(
          (a) => a.regionid == regionId
        );
      defaultReg.map((data) =>
        defRegionArr.push({
          label: data.regionname,
          id: data.regionid,
        })
      );
    }

    setDefreg({
      ...defreg,
      data: defRegionArr,
    });
    setRegionTypes({
      ...regionTypes,
      data: regionArr,
    });

    // props.hadledefregBack(defaultRegion);
  }, [
    props.loggedUserInfo.region_master && props.loggedUserInfo.region_master,
  ]);
 

  let defaultRegion = defreg.data.map((a) => a.label);
  const [show, setShow] = useState(false);
 

  const { oktaAuth, authState } = useOktaAuth();
  useEffect(() => {
    if (
      authState.isAuthenticated &&
      Object.keys(props.loggedUserInfo).length === 0
    ) {
      localStorage.setItem("idToken", authState.idToken);
      localStorage.setItem("clientId", API_CLIENT);
      let authInfo = {
        idToken: authState.idToken,
        clientId: API_CLIENT,
      };
      props.loginAuth(authInfo);
    }
  }, [authState]);

  const logout = () => {
    oktaAuth.signOut("/");
    props.logOut();
    JsCookie.remove("firlog");
  };


  // if (!authState.isAuthenticated) {
  //   oktaAuth.signInWithRedirect("/");
  // }

  const toggleVisiblity = () => {
    if (!show) {
      setShow(!show);
    }
  };

  // for autoclose region-popover
  const [s, setS] = useState(false);
  const [m, setM] = useState(null);
  const ref = useRef(null);
  const dispClick = (e) => {
    setS(!s);
    setM(e.target);
  };

  // end of auto close region-popover

  // for autoclose profile-popover
  const [c, setC] = useState(false);
  const [d, setD] = useState(null);
  const ref2 = useRef(null);
  const dispProfile = (e) => {
    setC(!c);
    setD(e.target);
  };

  // end of auto close profile-popover
  // assecibility features
  function handleEnter(event) {
    if (event.keyCode === 13) {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  }
  // end of assecibility features

  //document download call
  const { DocNum } = useParams()
  useEffect(() => {
    if (props.loggedUserInfo.userId)  {
      props.viewDocDownloadById({
        userId:userId,
        docId:DocNum,
      })
    }
  },[props.viewDocDownloadById, props.loggedUserInfo.userId, DocNum]
  )
  
  const regionPopover = () => (
    <>
      <div className="specifywh">
        <div class=" ">
          <div class=" ">
            {props.loggedUserInfo && props.loggedUserInfo.region_master
              ? regionTypes.data.map((data) => {
                  return (
                    <>
                      <div className="fullBg">
                        <a
                          tabIndex="0"
                          style={{
                            textDecoration: "none",
                            color: "#626468",
                            cursor: "pointer",
                          }}
                          // onKeyDown={handleEnter}
                          className="regionStyling"
                          onClick={(e) => handledata(e)}
                          onKeyPress={(e) => handledata(e)}
                        >
                          {data.label}
                        </a>
                        {/* {showRegions.toString()} */}
                        <hr />
                      </div>
                    </>
                  );
                })
              : ""}
          </div>
        </div>
      </div>
    </>
  );

  const profilePopover = () => (
    <>
      <div className="dropdown-content ">
        <div className="container names">
          <div className="OvalPro text-white">
            {props.loggedUserInfo.shortusername}
          </div>
          <div className="longname">{props.loggedUserInfo.longusername}</div>
        </div>

        <div
          className="settings"
          role="Settings Button"
          tabIndex="0"
          onClick={toggleVisiblity}
          onKeyPress={toggleVisiblity}
        >
          <hr className="horLine" aria-hidden="true" />
          <img alt="settings_icon" src={settings} aria-hidden="true" />
          <div className="droptext">{t("settings")}</div>
        </div>

        <div
          className="logout"
          role="Logout Button"
          tabIndex="0"
          onClick={logout}
          onKeyPress={logout}
        >
          <hr className="horLine" aria-hidden="true" />
          <img alt="logout_icon" src={logoutIcon} aria-hidden="true" />

          <div className="droptext">{t("logout")}</div>
        </div>
        
      </div>
    </>
  );
  const currentYear = (new Date().getFullYear())
  const footer = () => (
      <div>
          <footer>
              <div className=" footer footerStyle desktopFooter">
                  <div className="row ">
                      <div className="col-md-6 text-center text-md-left ">
                          <div ><img className="footerLogo" src={JllLogo}  alt="footer_logo"/>
                              <span className="copyRight" data-testid="CopyRight">
                                  {t("copyright", {currentYear})}
                      </span>
                          </div>
                      </div>
                      <div className="col-md-0 text-white text-center text-md-left ">
                      </div>
                      <div className="col-md-6 text-white  text-center text-md-center">
                          <span className="ps " data-testid="Privacy_Statement">
                          <a href="https://www.us.jll.com/en/privacy-statement" target="_blank" tabindex="0" aria-label="Privacy Statement link opens in a new tab" className="bb">{t("privacy")} </a>
                          <span className="tou"> <a href="https://www.us.jll.com/en/terms-of-service " target="_blank" tabindex="0" aria-label="Terms of Use link opens in a new tab" className="bb">{t("terms")}</a></span>
                              </span>
                          <span className=" follow">
                              <span className="fu" data-testid="Follow_Us">
                                  {t("follow")}
                                  </span>
                                  <a href="https://www.facebook.com/jll" target="_blank"  tabindex="0" aria-label="Facebook app opens in a new tab" className="bb"><img src={facebook} className="facebook" alt="facebook-logo"/></a>
                                  <a href="https://twitter.com/jllresearch" target="_blank" tabindex="0" aria-label="Twitter app opens in a new tab" className="bb"><img src={twitter} className="twitter" alt="twitter-logo"/></a>
                                  <a href="https://www.linkedin.com/company/jll" target="_blank" tabindex="0" aria-label="Linked in opens in a new tab" className="bb"><img src={linkedin} className="linkedin" alt="linkedin-logo"/></a>
                              </span>
                      </div>
                  </div>
              </div>
              <div className="mobileFooter footer ">
                  <div className="line footerStyle">
                      <div className="line">
                          <img className="footerLogo" src={JllLogo} alt="footer_logo" />
                      </div>
                      <div className="line follow">
                                  <span className="fu">
                                  {t("follow")}
                                  </span>
                                  <a href="https://www.facebook.com/jll" target="_blank" tabindex="0" aria-label="Facebook app opens in a new tab" className="bb"><img src={facebook} className="facebook" alt="facebook-logo"/></a>
                                  <a href="https://twitter.com/jllresearch" target="_blank" tabindex="0" aria-label="Twitter app opens in a new tab" className="bb"><img src={twitter} className="twitter" alt="twitter-logo"/></a>
                                  <a href="https://www.linkedin.com/company/jll" target="_blank" tabindex="0" aria-label="Linked in opens in a new tab" className="bb"><img src={linkedin} className="linkedin" alt="linkedin-logo"/></a>
                              </div>
                      <div className="line right text-white">
                         
                          <div className="line service">
                              <div className="ps line">
                               <a href="https://www.us.jll.com/en/privacy-statement" target="_blank" tabindex="0" aria-label="Privacy Statement link opens in a new tab" className="bb">{t("privacy")} </a>
                               <span className="tou">  <a href="https://www.us.jll.com/en/terms-of-service " target="_blank" tabindex="0" aria-label="Terms of Use link opens in a new tab" className="bb">{t("terms")}</a></span>
                              </div>
                             
                          </div>
                          <div className="copyRight  line" tabindex="-1">
                              {t("copyright", {currentYear})}
                          </div>
                      </div>

                  </div>
              </div>
          </footer>

          </div>
  )
  const [alertData, setDataBack] = useState({
    alert: ""
  });
  const getDataBacks = (childData) => {

    setDataBack({ ...alertData, ...(alertData.alert = childData) })
  }
    
  const [defaultData, setDefaultData] = useState({
    propertyTypes: [],
    topics: [],
    locations: [],
    languages: [],
    dateRange: [],
    intervals: [],
    docType: [],
  });
   
  const updateDropdown = (dropdownData) => {
    defaultData[Object.keys(dropdownData)[0]] = dropdownData[Object.keys(dropdownData)[0]];
    //defaultData.locations = props.region_Global[0]
    setDefaultData(defaultData);
  };
  const [showAlert, setShowAlert] = useState(false);
  return (
    <>
    {!isMobile ? (
      <>
      <Helmet>
            <title>JLL-Download Page</title>
      </Helmet>
      <div className="header col-sm-12 col-md-12 col-9 col-xl-12 pb-2">
        <a
          href={`${env}home`}
          alt="header_logo"
          className="headlogo"
          tabIndex="0"
        >
          {" "}
          <div className="allLogos">
            {" "}
            <div>
              <img className="header_logo" alt="header_logo" src={JLLLogo} />
            </div>
            <div className="verbar"></div>
            <div className="ReSearch">
              <img
                className="research_logo"
                alt="research_logo"
                src={ResearchLogo}
              />
            </div>
          </div>{" "}
        </a>

        <div
          className="globeRegion"
          onClick={dispClick}
          ref={ref}
          data-testid="regionButton"
        >
          <button className="selectorBtn" aria-label="Region Selector">
            {" "}
            <a className="globe" tabIndex="-1">
              {/* <img
                className="region"
                alt="globe_icon"
                src={region}
                tabIndex="-1"
              /> */}
              {/* <b className="EMEA"> {clicked ? dataBack : defaultRegion}</b> */}
            </a>
          </button>
        </div>
        <Overlay
          rootClose="true"
          onHide={() => setS(false)}
          show={s}
          target={m}
          placement="bottom"
          //container={ref.current}
        >
          <Popover>
            <Popover.Content>{regionPopover()}</Popover.Content>
          </Popover>
        </Overlay>

        <div
          className="profileIcon pt-4"
          onClick={dispProfile}
          aria-expanded="true"
          ref={ref2}
        >
          <button className="selectorBtn" aria-label="User Profile SubMenu">
            {" "}
            <div className="oval text-white" tabIndex="-1">
              {props.loggedUserInfo.shortusername}
            </div>
          </button>
        </div>
        <Overlay
          rootClose="true"
          onHide={() => setC(false)}
          show={c}
          target={d}
          placement="bottom"
          //container={ref2.current}
        >
          <Popover className="myUserProfile">
            <Popover.Content>{profilePopover()}</Popover.Content>
          </Popover>
        </Overlay>

        <SettingsContainer show={show} close={setShow} />
        <div className="logoMargin">
          {props.loggedUserInfo.client_logo && (
            <img
              className={
                props.loggedUserInfo.client_logo === "" ||
                props.loggedUserInfo.client_logo === null
                  ? "hide_logo"
                  : "client_logo"
              }
              src={props.loggedUserInfo.client_logo}
              alt="client_logo"
            />
          )}
        </div>
      </div>
    <div role="main"> <a className="skip-main" href="#main_WelcomeMessage"  tabIndex="1">Skip to main content</a>
        {props.loggedUserInfo.shortusername  ?
        <>
          <img className="homeImg" src={headerImage} alt="" aria-hidden="true" />
          <div className="container welcomeMsgContainer">
              <h1 className="row welcomeMessg" style={{ marginTop: "-15px"}}  id="main_WelcomeMessage">{t("hello", { props })}</h1>
          </div>
          <div className="container welcomeMsgContainer">
          <div className="row alertWelcomeTitle">
          <p data-testid="welcome" id="welcome" onClick={() => props.viewDocDownloadById({userId:userId,docId:DocNum,}) } style={{paddingTop:"3px", paddingBottom:"3px", textAlign: "left", marginTop: "45px", marginRight: "90px", marginLeft: "-20px", backgroundColor: "#495057", backdropFilter: "blur(5px)", borderRadius: "5px", paddingLeft: "2%", paddingRight: "2%", fontSize: "22px"}}>
          <p>Please wait while document is being downloaded.</p>
          If document does not download, please click <a className="downloadHere" >here</a>. 
          If you would like to create a new Alert or to utilize other functions of the R.E.Search site, please click <a style={{color: "#e30613"}} href={`${env}home`} >here</a>.
          </p>
          </div>
          </div>
        </>
        :<Spinner/>}
      </div>
      <div class="surveyFooterStyle">{footer()}</div>
    </>
    ) : (
    <>
    <Helmet>
            <title>JLL-Download Page</title>
      </Helmet>
      <div className="header col-sm-12 col-md-12 col-9 col-xl-12 pb-2">
        <a
          href={`${env}home`}
          alt="header_logo"
          className="headlogo"
          tabIndex="0"
        >
          {" "}
          <div className="allLogos">
            {" "}
            <div>
              <img className="header_logo" alt="header_logo" src={JLLLogo} />
            </div>
            <div className="verbar"></div>
            <div className="ReSearch">
              <img
                className="research_logo"
                alt="research_logo"
                src={ResearchLogo}
              />
            </div>
          </div>{" "}
        </a>

        <div
          className="globeRegion"
          onClick={dispClick}
          ref={ref}
          data-testid="regionButton"
        >
          <button className="selectorBtn" aria-label="Region Selector">
            {" "}
            <a className="globe" tabIndex="-1">
              {/* <img
                className="region"
                alt="globe_icon"
                src={region}
                tabIndex="-1"
              /> */}
              {/* <b className="EMEA"> {clicked ? dataBack : defaultRegion}</b> */}
            </a>
          </button>
        </div>
        <Overlay
          rootClose="true"
          onHide={() => setS(false)}
          show={s}
          target={m}
          placement="bottom"
          //container={ref.current}
        >
          <Popover>
            <Popover.Content>{regionPopover()}</Popover.Content>
          </Popover>
        </Overlay>

        <div
          className="profileIcon pt-4"
          onClick={dispProfile}
          aria-expanded="true"
          ref={ref2}
        >
          <button className="selectorBtn" aria-label="User Profile SubMenu">
            {" "}
            <div className="oval text-white" tabIndex="-1">
              {props.loggedUserInfo.shortusername}
            </div>
          </button>
        </div>
        <Overlay
          rootClose="true"
          onHide={() => setC(false)}
          show={c}
          target={d}
          placement="bottom"
          //container={ref2.current}
        >
          <Popover className="myUserProfile">
            <Popover.Content>{profilePopover()}</Popover.Content>
          </Popover>
        </Overlay>

        <SettingsContainer show={show} close={setShow} />
        <div className="logoMargin">
          {props.loggedUserInfo.client_logo && (
            <img
              className={
                props.loggedUserInfo.client_logo === "" ||
                props.loggedUserInfo.client_logo === null
                  ? "hide_logo"
                  : "client_logo"
              }
              src={props.loggedUserInfo.client_logo}
              alt="client_logo"
            />
          )}
        </div>
      </div>
    <div role="main"> <a className="skip-main" href="#main_WelcomeMessage"  tabIndex="1">Skip to main content</a>
        {props.loggedUserInfo.shortusername  ?
        <>
          <img className="homeImg" src={headerImage} alt="" aria-hidden="true" />
          <div className="container welcomeMsgContainer">
              <h1 className="row welcomeMessg" id="main_WelcomeMessage">{t("hello", { props })}</h1>
          </div>
          <div className="container welcomeMsgContainer">
          <div className="row welcomeTitle">
          <p data-testid="welcome" id="welcome" style={{textAlign: "left", marginTop: "45px", marginRight: "90px", marginLeft: "-10px", backgroundColor: "rgba(255, 255, 255, 0.5)", backdropFilter: "blur(5px)", borderRadius: "5px", paddingLeft: "2%", paddingRight: "2%", fontSize: "22px"}}>Please wait while document is being downloaded.
          </p> 
          <p data-testid="welcome" id="welcome" onClick={() => props.viewDocDownloadById({userId:userId,docId:DocNum,}) } style={{textAlign: "left", marginTop: "45px", marginRight: "90px", marginLeft: "-20px", backgroundColor: "rgba(255, 255, 255, 0.5)", backdropFilter: "blur(5px)", borderRadius: "5px", paddingLeft: "2%", paddingRight: "2%", fontSize: "22px"}}>
          If document does not download, please click <a className="downloadHere" >here</a>. 
          If you would like to create a new Alert or to utilize other functions of the R.E.Search site, please click <a style={{color: "#e30613"}} href={`${env}home`} >here</a>.
          </p>
          </div>
          </div>
        </>
        :<Spinner/>}
      </div>
      <div class="surveyFooterStyle">{footer()}</div>
    </>
    )}
    </>
    
  );
}



const updateRegionId = (updatedRegionData) => {
  return {
    type: SWITCH_REGION_SUCCESS,
    payload: updatedRegionData,
  };
};

const mapStateToProps = (props) => {
  var {auth, alertEmailDownload} = props;
  //const {DocNum} = this.props;
  const loggedUserInfo = auth.loggedUserInfo;
  return {
      //DocNum: DocNum,
      auth,
      loggedUserInfo,
      userId: loggedUserInfo.userId,
      defaultregionId: loggedUserInfo.defaultregionrd,
      defaultlanguageid:loggedUserInfo.defaultlanguageid,
      userName: loggedUserInfo.longusername,
      propertytype_master: loggedUserInfo.propertytype_master,
      topic_master: loggedUserInfo.topic_master,
      location_master: loggedUserInfo.location_master,
      quick_search: loggedUserInfo.quick_search,
      region_Global:loggedUserInfo.region_Global,
      viewDocDownloadByIdInfo: alertEmailDownload.file.viewDocDownloadByIdInfo,
  };
};
const mapDispatchToProps = dispatch => {
  return {
      loginAuth: authInfo => {
          dispatch(loginAuth(authInfo));
      },
      logOut: () => {
        dispatch(logOut());
      },
      viewDocDownloadById: (params) => {
          dispatch(viewDocDownloadById(params));
      },
      saveSettings: (params) => {
        if (JsCookie.get("tempRegionId")) {
          JsCookie.remove("tempRegionId"); //deleting tempRegionId from cookiess
        }
        JsCookie.set("tempRegionId", params.defaultregionId); //set selected region id to cookiess
        dispatch(updateRegionId(params.defaultregionId));
      },
      surveyroute: (params) => {
          dispatch(surveyroute(params));
      }
  };
};


const DownloadComponent = connect(mapStateToProps, mapDispatchToProps)(Download);
export default DownloadComponent;
