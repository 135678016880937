import {
    REMOVE_FROM_SEARCH_ALERTS_STARTED,
    REMOVE_FROM_SEARCH_ALERTS_SUCCESS,
    REMOVE_FROM_SEARCH_ALERTS_FAILURE,
    } from "../constants/alerts.constant";
    
    const initialState = {
      loading: false,
      removed_from_searchalerts:{
        loading: false,
        error:null,
        removed_docId: ""
      }
    };
    
    export default function removeFromSearchAlert(state = initialState, action) {
      switch (action.type) {
        
          case REMOVE_FROM_SEARCH_ALERTS_STARTED:
            return {
              ...state,
              removed_from_searchalerts: {...state.removed_from_searchalerts, loading: true}
            };
          case REMOVE_FROM_SEARCH_ALERTS_SUCCESS:
            return {
              ...state,
              removed_from_searchalerts: {...state.removed_from_searchalerts, loading: false, removed_docId: action.payload, error: null}
            };
          case REMOVE_FROM_SEARCH_ALERTS_FAILURE:
            return {
              ...state,
              removed_from_searchalerts: {...state.removed_from_searchalerts, loading: false, error: action.payload.error}
            };
        default:
          return state;
      }
    }