import {
    MY_FILES_STARTED,
    MY_FILES_SUCCESS,
    MY_FILES_FAILURE,
  } from "../constants/myFiles.constant";
  
  const initialState = {
    loading: false,
    myAllFiles: [],
    error: null
  };
  
  export default function getMyAllFilesReducer(state = initialState, action) {
    switch (action.type) {
      case MY_FILES_STARTED:
        return {
          ...state,
          loading: true
        };
      case MY_FILES_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          myAllFiles: [action.payload]
        };
      case MY_FILES_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error
        };
      default:
        return state;
    }
  }