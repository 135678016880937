import React, { Suspense } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
    useParams,
} from 'react-router-dom';
import Footer from '../components/MenuTabs/Footer/Footer'
import Spinner from "../components/Common/Spinner";
import DownloadComponent from '../components/AlertEmail/AlertEmail';


const env = process.env.REACT_APP_ENV;
const Survey = React.lazy(() => import('../components/Survey'));
const ThankYouComponent = React.lazy(() => import('../components/Survey/Thankyou'));

const Surveyroute = () => {
    const DocNum = "AF5CF4B7-F43C-4EBF-BEC2-0A35509A5E09"
    const BatchNum = "2828981"
    return (
        <div  id="page-container" >
            <div id="content-wrap">
                <Router>
                    <Suspense fallback={<Spinner />}>
                    <Switch>
                            <Route  path={`${env}Survey`} component={Survey} />
                            <Route  path={`${env}ThankYou`} component={ThankYouComponent} />
                            <Route exact path={env} render={() => (
                                <Redirect to={`${env}Survey`}/>
                                )}/>
                     </Switch>   
                    </Suspense>
                </Router>
            </div>
           
        </div>
    )
    
   
}
export default Surveyroute;
