import React from 'react';
import './spinner.scss'
export default class Spinner extends React.Component {
	render() {
		return (
			<div className="Spinner SpinnerDots">
				<div className="spinner-dot" />
				<div className="spinner-dot" />
				<div className="spinner-dot" />
			</div>
		);
	}
}


