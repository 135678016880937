import {
    GET_ALL_REIS_DELIVERABLES_STARTED,
    GET_ALL_REIS_DELIVERABLES_SUCCESS,
    GET_ALL_REIS_DELIVERABLES_FAILURE
  } from '../constants/auth.constant';
  
  const initialState = {
    loading: false,
    getAllReisDeliverablesInfo: [],
    error: null
  };
  
  export default function getAllReisDeliverablesReducer(state = initialState, action) {
    switch (action.type) {
      case GET_ALL_REIS_DELIVERABLES_STARTED:
        return {
          ...state,
          loading: true
        };
      case  GET_ALL_REIS_DELIVERABLES_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
         getAllReisDeliverablesInfo: [...action.payload.resi_deliverables_reports]
        };
      case GET_ALL_REIS_DELIVERABLES_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error
        };
      default:
        return state;
    }
  }