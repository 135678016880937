import {
    GET_ALL_FAQS_STARTED,
    GET_ALL_FAQS_SUCCESS,
    GET_ALL_FAQS_FAILURE
  } from '../constants/auth.constant';
  
  const initialState = {
    loading: false,
    getAllFaqsInfo: [],
    error: null
  };
  
  export default function getAllFaqsReducer(state = initialState, action) {
    switch (action.type) {
      case GET_ALL_FAQS_STARTED:
        return {
          ...state,
          loading: true
        };
      case GET_ALL_FAQS_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          getAllFaqsInfo: [...action.payload]
        };
      case GET_ALL_FAQS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error
        };
      default:
        return state;
    }
  }