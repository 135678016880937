import {
    OKTA_AUTH_STARTED,
    OKTA_AUTH_SUCCESS,
    OKTA_AUTH_FAILURE,
    CLEAR_HISTORY_SUCCESS
  } from '../constants/auth.constant';
  
  const initialState = {
    loading: false,
    loggedUserInfo: {},
    error: null
  };
  
  export default function todosReducer(state = initialState, action) {
    switch (action.type) {
      case OKTA_AUTH_STARTED:
        return {
          ...state,
          loading: true
        };
      case OKTA_AUTH_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          loggedUserInfo: {...state.loggedUserInfo, ...action.payload}
        };
      case OKTA_AUTH_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error
        };
        
      default:
        return state;
    }
  }