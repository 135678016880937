import  axios from "axios";
import * as rax from 'retry-axios';
import http from 'http';
import https from 'https';
import Swal from 'sweetalert2'
rax.attach();
 export const ajaxInstance = () => {
      const axiosAgent = axios.create({
        httpAgent: new http.Agent({
          keepAlive: true
        }),
        httpsAgent: new https.Agent({
          rejectUnauthorized: false,
          keepAlive: true,
          https: true
        })
      });
      axiosAgent.defaults.raxConfig = {
        retry:  3,
        noResponseRetries:  2,
        retryDelay:  100,
        httpMethodsToRetry:  ['GET', 'POST', 'HEAD', 'OPTIONS', 'DELETE', 'PUT','PATCH'],
        statusCodesToRetry:  [
          [100, 199],
          [419, 429],
          [500, 599]
        ],
        onRetryAttempt: retryErr => {
          const cfg = rax.getConfig(retryErr);
          console.error(
            '\n\n\n\n\n=================== Retry on error ' +
              cfg.currentRetryAttempt,
              retryErr
          );
        },
        instance: axiosAgent
      };
  
      // intercept the axios request.
      axiosAgent.interceptors.request.use(
         config => {
          
              return {
                ...config,
                headers: {
                  ...config.headers,
                  Authorization: 'Bearer ' +  JSON.parse(localStorage.getItem("okta-token-storage")).accessToken.accessToken,
                  clientId: localStorage.getItem("clientId"),
                  'Subscription-Key': 'ede064160e5e4ac3b8ec921a2c965cf8'
                },
                crossDomain: true,
                withCredentials:true,
              };
         
        },
        error=> {
          console.error('Error before sending request', error);
          return Promise.reject(error);
        }
      );
      // intercept the axios request.
      axiosAgent.interceptors.response.use(
        function(response) {
          return response;
        },
        function(error) {
          if (error?.response?.status >= 300) {
            Swal.fire({
              title: 'Something went wrong!',
              showCloseButton: false,
              showConfirmButton: true,
              confirmButtonText:"Ok",
              confirmButtonColor:"#e30613",
              icon: 'error',
              text:"Please try again later.",
            })
            console.error('Response Error: ', error);
          }
          return Promise.reject(error);
        }
      );
  
      return axiosAgent;
    }
