import React, { Suspense } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect
} from 'react-router-dom';
import { SecureRoute} from '@okta/okta-react';
import Footer from '../components/MenuTabs/Footer/Footer'
import Spinner from "../components/Common/Spinner";
const env = process.env.REACT_APP_ENV;

const MenuTabs = React.lazy(() => import('../components/MenuTabs/index'));
const Research = React.lazy(() => import('../components/MenuTabs/Research'));
const Home = React.lazy(() => import('../components/MenuTabs/Home'));
const MyAlerts = React.lazy(() => import('../components/MenuTabs/MyAlerts/indexx'));
const MyFavorites = React.lazy(() => import('../components/MenuTabs/MyFavorites'));
const MyFiles = React.lazy(() => import('../components/MenuTabs/MyFiles'));
const Explore = React.lazy(() => import('../components/MenuTabs/Explore'));
const REIS = React.lazy(() => import('../components/MenuTabs/REIS'));
const MobileSettingsContainer = React.lazy(() => import('../components/MenuTabs/Header/Settings/MobileSettings'));
const MobileRegion = React.lazy(() => import('../components/MenuTabs/Header/SelectRegion/MobileRegion'));
const MoreNewReportsData = React.lazy(() => import('../components/MenuTabs/Home/MoreNewReports/MoreNewReports'));
const MoreFeaturedReportsData = React.lazy(() => import('../components/MenuTabs/Home/MoreFeaturedReports/MoreFeaturedReports'));
const MorePopularReportsData = React.lazy(() => import('../components/MenuTabs/Home/MorePopularReports/MorePopularReports'));
const HelpCenter = React.lazy(() => import('../components/MenuTabs/HelpCenter/HelpCenter'));
const AllFaqsdata= React.lazy(() => import('../components/MenuTabs/HelpCenter/AllFaqs/AllFaqs'));
const MobileSeriesData=React.lazy(() => import('../components/MenuTabs/MyAlerts/MyReportAlerts/SeriesOfTable/MobileSeries'));
const SearchUrl = React.lazy(() => import('../components/MenuTabs/SearchUrl'));
const AlertsUnsubscribe = React.lazy(() => import('../components/MenuTabs/AlertsUnsubscribe'));
const SeriesOfTableData = React.lazy(() => import('../components/MenuTabs/MyAlerts/MyReportAlerts/SeriesOfTable/SeriesOfTable'));
const ElasticSearchUI = React.lazy(() => import('../components/MenuTabs/ElasticSearchUI/ElasticSearchUI'));
const routes = () => {
    return (
       <div  id="page-container" >
        <div id="content-wrap">
        <Router>
            <Suspense fallback={<Spinner />}>
                <MenuTabs />
                    <Switch>
                        <Route  path={`${env}home`} component={Home} />
                        <Route  path={`${env}explore`} component={Explore} />
                        <Route  path={`${env}alerts`} component={MyAlerts} />
                        <Route  path={`${env}favourites`} component={MyFavorites} />
                        <Route  path={`${env}files`} component={MyFiles} />
                        <Route  path={`${env}REIS`} component={REIS} />
                        <Route  path={`${env}research`} component={Research} />
                        <Route  path={`${env}regionSelect`} component={MobileRegion} /> 
                        <Route  path={`${env}settings`} component={MobileSettingsContainer} /> 
                        <Route  path={`${env}NewReports`} component={MoreNewReportsData} />
                        <Route  path={`${env}FeaturedReports`} component={MoreFeaturedReportsData} />
                        <Route  path={`${env}PopularReports`} component={MorePopularReportsData} />
                        <Route  path={`${env}helpCenter`} component={HelpCenter} />
                        <Route  path={`${env}SeeMoreFAQs`} component={AllFaqsdata} />
                        <Route  path={`${env}SeriesReportList`} component={MobileSeriesData} />
                        <Route  path={`${env}searchUrl`} component={SearchUrl} />
                        <Route  path={`${env}alertsUnsubscribe`} component={AlertsUnsubscribe} />
                        <Route  path={`${env}SeriesReportAlertsList`} component={SeriesOfTableData} />
                        <Route  path={`${env}ElasticSearchUI`} component={ElasticSearchUI} />
                        <Route exact path={env} render={() => (
                            <Redirect to={`${env}home`}/>
                            )}/>
                    </Switch>
            </Suspense>
        </Router>
      </div>
<Footer />
</div>
    )
}
export default routes;
