import {
    GET_ALL_HELPCENTER_STARTED,
    GET_ALL_HELPCENTER_SUCCESS,
    GET_ALL_HELPCENTER_FAILURE
} from "../constants/helpcenter.constant";

const initialState = {
    loading: false,
    getAllHelpCenterInfo: [],
    error: null
}

const getAllHelpCenter = (state = initialState, action) => {
    switch(action.type) {
        case GET_ALL_HELPCENTER_STARTED :
            return {
                ...state,
                loading: true
            }
        case GET_ALL_HELPCENTER_SUCCESS :
            return {
                ...state,
                loading: false,
                error : null,
                getAllHelpCenterInfo: [ action.payload ]
            }
        case GET_ALL_HELPCENTER_FAILURE :
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default :
            return state;
    }
}

export default getAllHelpCenter;